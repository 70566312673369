







































import {Component, Ref, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import PasswordService from "@/services/PasswordService"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import LoginService from "@/services/LoginService"
import SessionModule from "@/store/SessionModule"
import SnackbarModule from "@/store/SnackbarModule"
import Rules from "@/services/tool/Rules";

@Component
export default class PasswordRecoveryView extends Vue {

	@Ref() readonly form!: HTMLFormElement
	lang: any = getModule(LangModule).lang
	pw1: string = ""
	pw2: string = ""

  get rules() { return Rules }

	// rules = {
	// 	required: (v: string) => (!!v) || this.lang.emptyLabel,
	// 	min4Characters: (v: string) => (v.length + 1 > 4) || this.lang.fieldMin4Characters,
	// }

	created() {
		let sessionModule: SessionModule = getModule(SessionModule)
    sessionModule.session.token = ""
    sessionModule.saveSession()
	}

	patchPassword() {
		if (this.pw1 === this.pw2) {
			if ((this.form.validate()) && (this.$route.query.token)) {
				getModule(DialogModule).showDialog(
					 new Dialog(this.lang.warning, this.lang.passwordUpdateQuestion, async () => {
						 await PasswordService.passwordRecovery(this, this.$route.query.token.toString(), this.pw1)
						 await this.$router.push("/login")
					 })
				)
			}
		} else {
			getModule(SnackbarModule).makeToast(this.lang.matchPasswords)
		}
	}

}
